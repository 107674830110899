var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[_c('v-row',{staticClass:"my-0 h-auto w-screen bg-gray-100 flex flex-col lg:flex-row justify-between"},[(_vm.hideNavigation == false)?_c('v-col',{staticClass:"h-full z-40",attrs:{"cols":"1"}},[_c('v-navigation-drawer',{staticClass:"h-full z-40",attrs:{"absolute":"","dark":"","width":"200px"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{staticClass:"p-4",attrs:{"active-class":"red--text text--accent-4"}},[_c('v-list-item',{staticClass:"pb-2",attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-menu-open")]),_c('v-list-item-title',{staticClass:"pl-3"},[_vm._v("Collapse")])],1),_vm._l((_vm.links),function(item,i){return _c('v-list-item',{key:i,staticClass:"pb-2",attrs:{"to":item.to,"color":_vm.$route.name == item.title ? 'red' : ''}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"pl-3 font-normal text-xs"},[_vm._v(_vm._s(item.title))])],1)}),(_vm.hasSession)?_c('v-list-item',{staticClass:"pb-2",attrs:{"color":"red"},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-power")]),_c('v-list-item-title',{staticClass:"pl-3"},[_vm._v("Logout")])],1):_vm._e()],2)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"flex h-full w-full  my-0 mx-auto bg-gray-100 py-0",attrs:{"md":"10","cols":"12"}},[_c('v-container',{staticClass:"w-full h-full my-0 mx-0 py-0"},[_c('div',{staticClass:"flex flex-col w-full h-full my-0 py-0"},[_c('div',{staticClass:"w-full py-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"w-full"},[(
                _vm.hideNavigation == false || 
                _vm.currentRouteName === 'Init' || 
                _vm.currentRouteName === 'Verification' || 
                _vm.currentRouteName === 'Membership-success' || 
                _vm.currentRouteName === 'Membership-cancel' || 
                _vm.currentRouteName === 'Join')?_c('v-app-bar',{attrs:{"rounded":"lg","color":"gray accent-4"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.currentRouteName))]),_c('v-spacer'),(_vm.hasSession)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-logout")])],1)]}}],null,false,3950407763)},[_c('span',[_vm._v("logout")])]):_vm._e(),(_vm.hasSession)?_c('v-avatar',{attrs:{"size":"36"}},[(_vm.userInSession.image !== null)?_c('img',{attrs:{"src":_vm.userInSession.image_url,"alt":"user"}}):_c('Avatar',{attrs:{"color":"red","fullname":_vm.userInSession.fullName,"size":36}})],1):_vm._e()],1):_vm._e()],1)],1),_c('div',{staticClass:"h-full",class:_vm.centerContent ? '' : ''},[_c('router-view'),_c('snack-bar')],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }