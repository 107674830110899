import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/link',
    name: 'Link',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "link" */ '../views/Link/Link.vue'),
  },
  {
    path: '/init',
    name: 'Init',
    meta: {
      requiresAuth: true,
      cardLinked: false,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "init" */ '../views/Link/Init.vue'),
  },
  {
    path: '/update-item',
    name: 'UpdateItem',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "updateItem" */ '../views/Link/UpdateItem.vue'),
  },
  {
    path: '/redirect',
    name: 'Redirect',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Link/Redirect.vue'),
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Transactions/Transactions.vue'),
  },
  {
    path: '/bills',
    name: 'Bills',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Subscriptions/Subscriptions.vue'),
  },
  {
    path: '/alerts',
    name: 'Alerts',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts/Alerts.vue'),
  },
  {
    path: '/cards',
    name: 'Cards',
    meta: {
      requiresAuth: true,
      cardLinked: true,
      requiresActive: true,
    },
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Cards/Cards.vue'),
  },
  {
    path: '/my-account',
    name: 'My Account',
    meta: {
      requiresAuth: true,
      cardLinked: true,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Pages/MyAccount.vue')
  },
  {
    path: '/membership',
    name: 'Membership',
    meta: {
      requiresAuth: true,
      cardLinked: false,
      requiresActive: false,
    },
    component: () => import(/* webpackChunkName: "membership" */ '../views/Membership/Redirects/MembershipRedirect.vue')
  },
  {
    path: '/join',
    name: 'Join',
    meta: {
      requiresAuth: true,
      cardLinked: false,
      requiresActive: false,
    },
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Membership/Join.vue'),
  },
  {
    path: '/membership-success',
    props: route => ({ query: route.query.session_id }),
    name: 'Membership-success',
    meta: {
      requiresAuth: true,
      cardLinked: false,
      requiresActive: false,
    },
    component: () => import(/* webpackChunkName: "success" */ '../views/Membership/Redirects/RedirectSuccess.vue')
  },
  {
    path: '/membership-cancel',
    name: 'Membership-cancel',
    meta: {
      requiresAuth: true,
      cardLinked: false,
      requiresActive: false,
    },
    component: () => import(/* webpackChunkName: "cancel" */ '../views/Membership/Redirects/RedirectCancel.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Pages/Login.vue')
  },
  {
    path: '/verify-email',
    props: route => ({ query: route.query.token }),
    name: 'User Verification',
    component: () => import(/* webpackChunkName: "user-verification" */ '../views/Pages/UserVerification.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/verification',
    name: 'Verification',
    component: () => import(/* webpackChunkName: "user-verification" */ '../views/Pages/Verification.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/Pages/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    props: route => ({ query: route.query.token }),
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/Pages/ResetPassword.vue')
  },
  {
    path: '/404',
    name: 'Error',
    component: () => import(/* webpackChunkName: "404" */ '../views/Pages/404.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {

  // start session if it doesn't exist
  if (localStorage.getItem('vue-session-key') === null || localStorage.getItem('vue-session-key') === undefined) {
    Vue.prototype.$session.start()
  }

  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    // if the user is logged in, continue
    if (JSON.parse(localStorage.getItem('vue-session-key')).access_token !== undefined && to.name !== 'Login') {
      //if the user is logged in and has not verified their email
      if (JSON.parse(localStorage.getItem('vue-session-key')).user.emailVerified === false && to.name !== 'Verification') {
        next({ path: '/verification' })
      } else {
        if ((to.name === 'Verification' || to.name === 'User Verification' ) && JSON.parse(localStorage.getItem('vue-session-key')).user.emailVerified === true) {
          next({ path: '/' })
        } else {
          next()
        }
      }

      // if user is not active redirect to join page
      if (to.matched.some(record => record.meta.requiresActive) && JSON.parse(localStorage.getItem('vue-session-key')).user.status === false) {
        next({ path: '/join' })
      }

      if (to.matched.some(record => record.meta.cardLinked) && 
          JSON.parse(localStorage.getItem('vue-session-key')).user.cardLinked === false && 
          JSON.parse(localStorage.getItem('vue-session-key')).user.status === true
          ) {
        store.dispatch('requestLinkToken', JSON.parse(localStorage.getItem('vue-session-key')).user.id)
        next({ path: '/init' })
      }

      // if(to.name === 'Init') {
      //   store.dispatch('requestLinkToken', JSON.parse(localStorage.getItem('vue-session-key')).user.id)
      // }

    } else {
      next({ path: '/login' })
    }
  }
  else {
    // if no auth required and the user is logged in
    if (JSON.parse(localStorage.getItem('vue-session-key')).access_token !== undefined) {
      if (to.name === 'Login') {
        // if the user is logged in and is trying to go to the login page, redirect them to the dashboard
        next({ path: '/' })
      } else if ((to.name === 'User Verification' || to.name === 'Verification')  && JSON.parse(localStorage.getItem('vue-session-key')).user.emailVerified === true) {
        next({ path: '/' })
      } else {
        next()
      }
    } else {
      next()
    }

  }

})

export default router
