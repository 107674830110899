<template>
  <v-app class="">
    <v-row class="my-0 h-auto w-screen bg-gray-100 flex flex-col lg:flex-row justify-between">
      <v-col cols="1" v-if="hideNavigation == false" class="h-full z-40">
        <v-navigation-drawer v-model="drawer" absolute dark width="200px" class="h-full z-40">
          <!-- color="#f56f42" -->
          <v-list nav dense>
            <v-list-item-group active-class="red--text text--accent-4" class="p-4">
              <v-list-item color="red" class="pb-2" @click.stop="drawer = !drawer">
                <v-icon small>mdi-menu-open</v-icon>
                <v-list-item-title class="pl-3">Collapse</v-list-item-title>
              </v-list-item>

              <v-list-item v-for="(item, i) in links" :key="i" class="pb-2" :to="item.to"
                :color="$route.name == item.title ? 'red' : ''">
                <v-icon small>{{ item.icon }}</v-icon>
                <span class="pl-3 font-normal text-xs">{{ item.title }}</span>
              </v-list-item>

              <v-list-item v-if="hasSession" color="red" class="pb-2" @click="logout">
                <v-icon small>mdi-power</v-icon>
                <v-list-item-title class="pl-3">Logout</v-list-item-title>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>

      <v-col md="10" cols="12" class="flex h-full w-full  my-0 mx-auto bg-gray-100 py-0">
        <v-container class="w-full h-full my-0 mx-0 py-0">
          <div class="flex flex-col w-full h-full my-0 py-0">
            <div cols="12" class="w-full py-0">
              <v-container class="w-full">
                <v-app-bar rounded="lg" v-if="
                  hideNavigation == false || 
                  currentRouteName === 'Init' || 
                  currentRouteName === 'Verification' || 
                  currentRouteName === 'Membership-success' || 
                  currentRouteName === 'Membership-cancel' || 
                  currentRouteName === 'Join'" 
                  color="gray accent-4">
                  <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

                  <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-tooltip bottom v-if="hasSession">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="logout">
                        <v-icon small>mdi-logout</v-icon>
                      </v-btn>
                    </template>
                    <span>logout</span>
                  </v-tooltip>

                  <v-avatar size="36" v-if="hasSession">
                    <img v-if="userInSession.image !== null" :src="userInSession.image_url" alt="user">
                    <Avatar v-else color="red" :fullname="userInSession.fullName" :size=36></Avatar>
                  </v-avatar>
                </v-app-bar>
              </v-container>
            </div>

              <div class="h-full" :class="centerContent ? '' : ''">
                <router-view />
                <snack-bar />
              </div>
          </div>
        </v-container>
      </v-col>
    </v-row>

  </v-app>
</template>

<script>

import SnackBar from './components/Partials/SnackBar.vue';
import { mapGetters } from 'vuex';
import Avatar from 'vue-avatar-component';

export default {
  components: { SnackBar, Avatar },
  name: 'App',
  computed: {
    routeName() {
      return this.$route.name
    },
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters({
      hideNavigation: 'getHideNavState',
      // userInSession: 'getUser'
    }),
    userInSession() {
      return this.$session.get('user')
    },
    hasSession() {
      return this.$session.has('access_token')
    },
  },
  data: () => ({
    drawer: true,
    links: [
      {
        icon: "mdi-monitor-dashboard",
        title: "Dashboard",
        to: "/"
      },
      {
        icon: "mdi-credit-card-check",
        title: "Cards",
        to: "/cards"
      },
      {
        icon: "mdi-cash-multiple",
        title: "Transactions",
        to: "/transactions"
      },
      {
        icon: "mdi-playlist-check",
        title: "Bills",
        to: "/bills"
      },
      // {
      //   icon: "mdi-timer-alert",
      //   title: "Alerts",
      //   to: "/alerts"
      // },
      {
        icon: "mdi-wallet-membership",
        title: "Membership",
        to: "/membership"
      },
      {
        icon: "mdi-account",
        title: "Profile",
        to: "/my-account"
      },
    ],
    centerContent: false,
  }),
  created() {
    this.$session.start()
  },
  watch: {
    routeName() {
      if (this.routeName == 'Login' ||
        this.routeName == 'Forgot Password' ||
        this.routeName == 'Reset Password' ||
        this.routeName == 'Verification' ||
        this.routeName == 'User Verification' ||
        this.routeName == 'Init' ||
        this.routeName == 'Membership-success' ||
        this.routeName == 'Membership-cancel' ||
        this.routeName == 'Join') {
        this.$store.dispatch('setHideNav', true)
        this.centerContent = true
      } else {
        this.$store.dispatch('setHideNav', false)
        this.centerContent = false
      }
    },

  },

  methods: {
    logout() {
      this.$session.destroy()
      this.$router.push({ name: 'Login' })
    },
  },
};
</script>

<style>
.v-list-item--active {
  color: #ffffff !important;
  /* background-color: #ffff !important; */
}
</style>
