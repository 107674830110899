import Vue from 'vue';

export const SET_LAYOUT_STATE = (state) => {
    state.layoutState = !state.layoutState
}

export const SET_HIDENAV_STATUS = (state, data) => {
    state.hideNav = data
}

export const SET_LOADING_STATUS = (state, data) => {
    state.loading = data
}

export const SET_REGISTER_FORM_ERRORS = (state, data) => {
    state.registerFormErrors = data
}

export const SET_LOGIN_FORM_ERRORS = (state, data) => {
    state.loginFormErrors = data
}

export const SET_REGISTER_FORM_RESPONSE = (state, {message, user}) => {
    state.registerFormResponse = message
    state.user = user
    // state.userToken = token

    // Vue.prototype.$session.start()
    // Vue.prototype.$session.set('token', token)
    // Vue.prototype.$session.set('user', user)
}

export const SET_USER_TOKEN = (state, data) => {
    state.userToken = data
}

export const SET_USER = (state, data) => {
    state.user = data.user
    Vue.prototype.$session.start()
    Vue.prototype.$session.set('access_token', data.access_token)
    Vue.prototype.$session.set('refresh_token', data.refresh_token)
    Vue.prototype.$session.set('user', data.user)
}

export const RESET_LOGIN_ERRORS = (state) => {
    state.loginFormErrors = {}
}

export const RESET_REGISTER_ERRORS = (state) => {
    state.registerFormErrors = {}
}

//forgot password
export const FORGOT_PASSWORD_RESPONSE = (state, data) => {
    state.forgotPasswordResponse = data
}

export const FORGOT_PASSWORD_ERROR = (state, data) => {
    state.forgotPasswordErrors = data
}

export const CLEAR_PASSWORD_FORGOT_ERRORS = (state) => {
    state.forgotPasswordErrors = null
}

export const CLEAR_PASSWORD_FORGOT_RESPONSE = (state) => {
    state.forgotPasswordResponse = null
}

//reset password
export const RESET_PASSWORD_RESPONSE = (state, data) => {
    state.resetPasswordResponse = data
}

export const RESET_PASSWORD_ERROR = (state, data) => {
    state.resetPasswordErrors = data
}

export const CLEAR_PASSWORD_RESET_ERRORS = (state) => {
    state.resetPasswordErrors = null
}

export const CLEAR_PASSWORD_RESET_RESPONSE = (state) => {
    state.resetPasswordResponse = null
}

export const SET_LINK_TOKEN = (state, data) => {
    state.linkToken = data
}

export const SET_UPDATE_TOKEN = (state, data) => {
    state.updateToken = data
}

// snackbar
export const SET_SNACKBAR_STATUS = (state, {status, message, type}) => {
    state.showSnackBar = status
    state.snackBarMessage = message
    state.snackBarType = type
}