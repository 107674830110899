import axios from 'axios'
import Vue from 'vue'
import { Messages } from '@/utils/constants';

export const setLayoutState = ({ commit }) => {
    commit('SET_LAYOUT_STATE')
}

export const setHideNav = ({ commit }, payload) => {
    commit('SET_HIDENAV_STATUS', payload)
}

export const register = async ({ commit }, payload) => {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/user', payload, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        commit('SET_REGISTER_FORM_RESPONSE', response.data)
    } catch (error) {
        commit('SET_REGISTER_FORM_ERRORS', error.response.data.errors)
    }
}

export const login = async ({ commit }, payload) => {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/login', payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        let userDetails = {
            user: response.data.user,
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token
        }
        commit('SET_USER', userDetails)
    } catch (error) {
        commit('SET_LOGIN_FORM_ERRORS', error.response.data.error)
        commit('SET_SNACKBAR_STATUS', {status: true, message: 'Authentication failed', type: Messages.ERROR})
        // commit('SET_LOGIN_FORM_ERRORS', JSON.stringify(error.message))
    }
}

export const resetLoginErrors = ({ commit }) => {
    commit('RESET_LOGIN_ERRORS')
}

export const resetRegisterErrors = ({ commit }) => {
    commit('RESET_REGISTER_ERRORS')
}

// Forgot password
export const forgotPassword = async ({ commit }, payload) => {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/user/forgot-password', payload, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.status === 200) {
            commit('FORGOT_PASSWORD_RESPONSE', 'Password reset link sent to your email')
        }
    } catch (error) {
        commit('FORGOT_PASSWORD_ERROR', error.response.data.message)
    }
}


export const clearPasswordForgotErrors = ({ commit }) => {
    commit('CLEAR_PASSWORD_FORGOT_ERRORS')
}

export const clearPasswordForgotResponse = ({ commit }) => {
    commit('CLEAR_PASSWORD_FORGOT_RESPONSE')
}

// Reset password
export const resetPassword = async ({ commit }, payload) => {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/user/reset-password', payload, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        commit('RESET_PASSWORD_RESPONSE', response.data.message)
    } catch (error) {
        commit('RESET_PASSWORD_ERROR', error.response.data.message)
    }
}


export const clearPasswordResetErrors = ({ commit }) => {
    commit('CLEAR_PASSWORD_FORGOT_ERRORS')
}

export const clearPasswordResetResponse = ({ commit }) => {
    commit('CLEAR_PASSWORD_FORGOT_RESPONSE')
}

// link token
export const requestLinkToken = async ( { commit }, userId) => {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/link-token', {user_id: userId}, {headers: {"Content-Type": "application/json"}})
        commit('SET_LINK_TOKEN', response.data)
    } catch (error) {
        let payload = {
            status: true,
            message: 'Failed to update token',
            type: Messages.ERROR
        }
        commit('SET_SNACKBAR_STATUS', payload)
    }
}

// update token
export const requestUpdateToken = async({ commit }, payload) => {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/update-token', payload, {headers: {"Content-Type": "application/json"}})
        commit('SET_UPDATE_TOKEN', {token: response.data, item: payload.item_id})
    } catch (error) {
        let payload = {
            status: true,
            message: 'Failed to update token',
            type: Messages.ERROR
        }
        commit('SET_SNACKBAR_STATUS', payload)
    }
}

// snack bar
export const showSnackBar = ({ commit }, payload) => {
    commit('SET_SNACKBAR_STATUS', payload)
}

// refresh token
export const refreshUserToken = async ({ commit }) => {
    try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/refreshToken', 
        {headers: {'Authorization': 'Bearer ' + Vue.prototype.$session.get('refresh_token')}})
        let userDetails = {
            user: response.data.user,
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token
        }
        commit('SET_USER', userDetails)
        
        // reload page
        window.location.reload()
    } catch (error) {
        commit('RESET_REGISTER_ERRORS')
    }
}