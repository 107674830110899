<template>
    <v-snackbar v-model="showSnackBar" :color="colour">
        {{ message }}

        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="close">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { Messages } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
        }
    },
    computed: {
        colour() {
            if (this.snackBarType === Messages.NONE) {
                return ''
            } else if (this.snackBarType === Messages.ERROR) {
                return 'red'
            } else {
                return 'green'
            }
            // return this.snackBarType === Messages.ERROR ? 'red' : 'green'
        },
        ...mapGetters({
            storeShowSnackBar: 'getSnackBarState',
            message: 'getSnackBarMessage',
            snackBarType: 'getSnackBarType'
        }),
        showSnackBar: {
            get() {
                return this.storeShowSnackBar
            },
            set(value) {
                this.$store.dispatch('showSnackBar',  { status: value, message: '', type: Messages.NONE })
            }
        }
    },

    methods: {
        close() {
            this.$store.dispatch('showSnackBar',  { status: false, message: '', type: Messages.NONE })
        },
    }

}
</script>

<style>

</style>