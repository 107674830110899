import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/index.css'
import VueSession from 'vue-session'
import axios from 'axios'

Vue.config.productionTip = false

axios.interceptors.request.use(
  config => {
      config.headers['Access-Control-Allow-Origin'] = 'https://dashboard.pennytoast.com'
      config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'

    // check request is not to refreshToken endpoint
    if (config.url !== process.env.VUE_APP_API_URL + '/refreshToken') {
      const token = Vue.prototype.$session.get('access_token')
      if (Vue.prototype.$session.has('access_token')) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
    } else {
      const token = Vue.prototype.$session.get('refresh_token')
      if (Vue.prototype.$session.has('refresh_token')) {
        config.headers['Authorization'] = 'Bearer ' + token
      }

    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    return await axios
      .get(process.env.VUE_APP_API_URL + '/refreshToken', {
        'Authorization': 'Bearer ' + Vue.prototype.$session.get('refresh_token')
      })
      .then((response) => {

        // save the new token
        Vue.prototype.$session.set('access_token', response.data.access_token)
        Vue.prototype.$session.set('refresh_token', response.data.refresh_token)
        Vue.prototype.$session.set('user', response.data.user)

        error.response.config.headers["Authorization"] =
          "Bearer " + response.data.access_token;
        // Retry the initial call, but with the updated token in the headers. 
        // Resolves the promise if successful
        return axios(error.response.config);
      })
      .catch((error2) => {
        // Retry failed, clean up and reject the promise
        Vue.prototype.$session.clear() 
        // fixme: this logs out the user even if it is not related to the token
        // eg on email verification, if the server responds with 401, the user is logged out even if the token is valid
        router.go("/login");
        return Promise.reject(error2);
      })
  }
);

var options = {
  persist: true
}
Vue.use(VueSession, options)


new Vue({
  router,
  store,
  vuetify,
  options,
  render: h => h(App)
}).$mount('#app')
