// import moment from "moment"

export default {
    layoutState: false,
    hideNav: true,
    loading: false,
    registerFormErrors: {},
    loginFormErrors: {},
    registerFormResponse: {},

    userToken: null,
    user: null,

    forgotPasswordResponse: null,
    forgotPasswordErrors: null,

    resetPasswordResponse: null,
    resetPasswordErrors: null,

    linkToken: null,
    updateToken: {},

    showSnackBar: false,
    snackBarMessage: null,
    snackBarType: null,
}